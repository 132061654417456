<template>
    <div>
        <scorm-player :path="`/scorm/${this.course_id}`" :student-data="studentData" @commit="commit" @finish="finish" @get-diagnostic="getDiagnostic" @get-error-string="getErrorString" @get-last-error="getLastError" @get-value="getValue" @initialize="initialize" @set-value="setValue" class="scorm-player"></scorm-player>
    </div>
</template>
<script>
import ScormPlayer from "vue-scorm-player";

export default {
    components: {
        ScormPlayer
    },
    props: [
        'course_id',
        'scormProgress'
    ],
    data: function () {
        return {
            studentData: [],
            makeAPICall: false,
            isInProgress: false,
        };
    },
    created: function () {
        console.clear();
    },
    methods: {
        initialize: function () {
            this.scormProgress.forEach(data => {
                this.updateObjectKeyValue(window.API, data.key, data.value);
            });
        },
        finish: function () {

        },
        getValue: function (element) {

        },
        setValue: function (element, value) {
            console.log(element, value);
            this.$http.post('employees/scorm-progress', {
                courseId: this.course_id,
                key: element,
                value: value,
            }).then(resp => {
                if (this.isInProgress === false) {
                    this.isInProgress = true;
                    this.$http.get('/employees/check-for-scorm-completion', {
                        params: {
                            courseId: this.course_id,
                        }
                    }).then(response => {
                        this.isInProgress = false;
                        this.status = response.data.status;
                        if (response.data.scormPassed == 1) {
                            this.submitEmployeeData();
                        }
                    });
                }
            });
        },
        commit: function () {

        },
        getLastError: function () {

        },
        getErrorString: function (errorCode) {

        },
        getDiagnostic: function (errorCode) {

        },
        updateObjectKeyValue: function (obj, key, value) {
            if (typeof obj === 'undefined') {
                return false;
            }

            var _index = key.indexOf('.');

            if (_index > -1) {
                return this.updateObjectKeyValue(obj[key.substring(0, _index)], key.substr(_index + 1), value);
            }
            obj[key] = value;
        },
        submitEmployeeData: function () {
            var formattedLessontest = {
                is_last_lesson: 1,
                course_id: this.course_id,
                test_id: 0,
                percentageScored: window.API.cmi.core.score.raw,
                resultStatus: this.status,
                test_type: "scorm",
                isScorm: 1,
            }
            this.$emit('save-course-completion', formattedLessontest);
        }
    }
}
</script>