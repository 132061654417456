<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-2">
        <div class="col-lg-6 col-7"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card>
        <!-- Card header -->
        <h2 slot="header" class="mb-0" v-if="folder_id">
          Edit New Course Folder
        </h2>
        <h2 slot="header" class="mb-0" v-else>
          Add New Course Folder
        </h2>
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit()" enctype="multipart/form-data">
            <div class="form-row">
              <div class="col-md-6">
                <base-input label="Folder Name" name="Folder Name" placeholder="Folder Name" rules="required"
                  v-model="folder.folder_name">
                </base-input>
              </div>
              <div class="col-md-12 mb-2">
                <label class="form-control-label">Folder Description</label>
                <textarea placeholder="Folder Description" class="form-control"
                  v-model="folder.folder_description"></textarea>
              </div>
              <div class="col-md-4">
                <label class="form-control-label">Course Folder Cost *</label>
                <money class="form-control" v-model="folder.cost" v-bind="money"></money>
              </div>
              <div class="col-md-4">
                <label class="form-control-label">Certificate</label>
                <el-select filterable placeholder="Select Certificate" v-model="certificate_id" style="width:100%">
                  <el-option v-for="option in certificates" class="select-primary" :value="option.value"
                    :label="option.label" :key="option.value">
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-4">
                <label class="form-control-label">Certificate Valid For (Days)</label>

                <base-input min="1" name="Validity" type="number" v-model="folder.expiry"></base-input>
              </div>

              <div class="col-md-3" v-if="folder.autoassign">
                <base-input label="Job Title">
                  <el-select
                    class="mr-3"
                    style="width: 100%"
                    placeholder="Select Job Title"
                    v-model="job_title"
                  >
                    <el-option
                      v-for="(option, index) in job_titles"
                      class="select-primary"
                      :value="option.id"
                      :label="option.name"
                      :key="'job_title_' + index"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <!-- <div class="col-md-3 ml-3 mt-2">
                <label class="form-control-label">Auto-Assign based on Job Title</label>
                <div class="d-flex">
                  <base-switch class="mr-1" type="success" v-model="folder.autoassign"></base-switch>
                </div>
              </div> -->
              <div class="col-md-2 ml-3 mt-2">
                <label class="form-control-label">Status</label>
                <div class="d-flex">
                  <base-switch class="mr-1" type="success" v-model="folder.status"></base-switch>
                </div>
              </div>

            </div>
            <div class="text-right mt-2">
              <base-button class="custom-btn" type="danger" @click="$router.go(-1)">Cancel</base-button>
              <base-button class="custom-btn" @click.prevent="saveFolders">{{
                folder_id !== "" ? " Update" : "Submit"
              }}</base-button>
            </div>
          </form>
        </validation-observer>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Table, TableColumn, Select, Option } from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import vSelect from "vue-select";
import { Money } from "v-money";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Money,
  },
  data() {
    return {
      hot_user: "",
      hot_token: "",
      config: "",
      datePicker: "",
      folder_id: "",
      folder: {
        folder_name: "",
        folder_description: "",
        expiry: "",
        course: "",
        cost: "",
        status: true,
        autoassign:false,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      certificates: [],
      certificate_id: "",
      job_titles:"",
      job_title:"",
    };
  },
  created() {
    if (localStorage.getItem("hot-token")) {
      this.hot_user = localStorage.getItem("hot-user");
      this.hot_token = localStorage.getItem("hot-token");
    }

    this.config = {
      headers: {
        authorization: "Bearer " + localStorage.getItem("hot-token"),
        "content-type": "application/json"
      }
    };

    this.$http.get("employees/jobTitles").then((resp) => {
        this.job_titles = resp.data;

      });

    this.$http.get("course/unassignedCertificates").then(resp => {
      this.certificates = [];
      for (let certificate of resp.data) {
        let obj = {
          value: certificate.id,
          label: certificate.name
        };
        this.certificates.push(obj);
      }
    });

    if (this.$route.query.id) {
      this.folder_id = this.$route.query.id;
      this.$http
        .get("course/coursefolder/" + this.folder_id, this.config)
        .then(resp => {
          let data = resp.data;
          this.folder.folder_name = data.folder_name;
          this.folder.folder_description = data.folder_description;
          this.folder.cost = data.folder_cost,
          this.folder.expiry = data.expiry;
          this.certificate_id = data.certificate_id;
          this.folder.autoassign = data.auto_assign === 0 ? false : true ;
          this.job_title = data.job_title_id;
        });
    }


  },
  methods: {
    saveFolders() {
      if (
        this.folder.folder_name !== "" &&
        this.folder.folder_description !== "" && this.folder.cost !== ""
      ) {
        if (this.folder_id !== "") {
          this.$http
            .put(
              "course/coursefolder/" + this.folder_id,
              {
                folder_id: this.folder_id,
                folder_name: this.folder.folder_name,
                folder_description: this.folder.folder_description,
                folder_status: this.folder.status,
                folder_cost: this.folder.cost,
                expiry: this.folder.expiry,
                certificate_id: this.certificate_id,
                auto_assign: this.folder.autoassign,
                ...(this.folder.autoassign && { job_title_id: this.job_title })
              },
              this.config
            )
            .then(resp => {
              Swal.fire({
                title: "Success!",
                text: `Folder Has been Updated!`,
                icon: "success"
              });
              this.$router.push("/course_folder");
            });
        } else {
          this.$http
            .post(
              "course/coursefolder",
              {
                folder_name: this.folder.folder_name,
                folder_description: this.folder.folder_description,
                expiry: this.folder.expiry,
                folder_status: this.folder.status,
                folder_cost: this.folder.cost,
                certificate_id: this.certificate_id,
                auto_assign: this.folder.autoassign,
                ...(this.folder.autoassign && { job_title_id: this.job_title }) // This is the correct syntax
              },
              this.config
            )
            .then(resp => {
              Swal.fire({
                title: "Success!",
                text: `Folder Has been Added!`,
                icon: "success"
              });
              this.$router.push("/course_folder");
            });
        }
      } else {
        return Swal.fire({
          title: "Error!",
          text: `All fields are required!`,
          icon: "error"
        });
      }
    },
  }
};
</script>
<style>
.stripe-card {
  border: 1px solid grey;
}

.stripe-card.complete {
  border-color: green;
}

.logo-size {
  width: 60%;
  height: auto;
}
</style>
